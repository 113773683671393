import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
// import SEO from 'react-seo-component';
/*-----pages-----*/
import {Home} from './Home';
// import {Test} from './Test';
import {NotFound} from './404';

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route path="/" component={Home}>
              {/*<Route path='/home/test' component={Test} />*/}
            </Route>
            {/*<Route component={blog} />*/}
            <Route component={NotFound}/>
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
