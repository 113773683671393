import React, {Component} from 'react';
import {Reveal} from 'react-reveal/';

class frelencerBanner extends Component {
  multipleReplace(obj, string) {
    let thisString = string;
    for (let x in obj) {
      thisString = thisString.replace(new RegExp(x, 'g'), obj[x]);
    }
    return thisString;
  }

  Logos(data, size = 50) {
    return "<a href='"+data.link+"' title='"+data.title+"' class='frameworkLogo logo-"+data.id+"'><img src='"+require(`../../image/${data.logo}`)+"' alt='"+data.title+"' title='"+data.title+"' width='"+size+"' height='"+size+"' /><span>"+data.title.substring(1)+"</span></a>";
  }

  render() {
    let siteData = this.props.siteData.banner;

    return (
      <section className="frelencer_banner_area" id="home">
        <div className="home_bubble">
          <div className="bubble b_one"></div>
          <div className="bubble b_two"></div>
          <div className="bubble b_three"></div>
          <div className="bubble b_four"></div>
          <div className="bubble b_five"></div>
          <div className="bubble b_six"></div>
        </div>
        <Reveal effect="fadeInRight" duration={500}>
          <img className="s_shap" src={require('../../image/banner_img.svg')}
               alt="Green Art Business & Magento 2 / Adobe Commerce" title="Green Art Business & Magento 2 / Adobe Commerce"/>
        </Reveal>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="frelencer_content">
                <Reveal effect="fadeInUp" duration={500}>
                  <h2 className="t_color"
                      dangerouslySetInnerHTML={{__html: this.multipleReplace({'Magento2': this.Logos(siteData.frameworkLogos[0], 70), 'Adobe Commerce': this.Logos(siteData.frameworkLogos[1], 80)}, siteData.text)}}/>
                  {/*<p className="t_color"><span>{siteData.framework}</span></p>*/}
                </Reveal>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="frelencer_img">
                <Reveal effect="fadeInLeft" duration={1400}>
                  <img className="f_img_one"
                       src={require('../../image/f_man.png')} alt="developer" title="developer"/>
                </Reveal>
                <Reveal effect="fadeInRight" duration={1200}>
                  <img className="f_img_two"
                       src={require('../../image/table.png')} alt="Table" title="Table"/>
                </Reveal>
                <Reveal effect="fadeInDown" duration={1600}>
                  <img className="f_img_three"
                       src={require('../../image/cup.png')} alt="Cup" title="Cup"/>
                </Reveal>
                <Reveal effect="fadeInDown" duration={1600}>
                  <img className="f_img_four"
                       src={require('../../image/shadow.png')} alt="shadow" title="shadow"/>
                </Reveal>
                {/*<Reveal effect="fadeInDown" duration={2000}>*/}
                {/*  <div className="f_img_five">*/}
                {/*    <img className="" src={require('../../image/php.png')} alt="Php" title="Php"/>*/}
                {/*  </div>*/}
                {/*</Reveal>*/}
                {/*<div className="f_img_one">*/}
                {/*  <img className="" src={require('../../image/c++.png')}*/}
                {/*       alt=""/>*/}
                {/*</div>*/}
                {/*<div className="f_img_two">*/}
                {/*  <img className="" src={require('../../image/c++.png')}*/}
                {/*       alt=""/>*/}
                {/*</div>*/}
                {/*<div className="f_img_tree">*/}
                {/*  <img className="" src={require('../../image/c++.png')}*/}
                {/*       alt=""/>*/}
                {/*</div>*/}
                {/*<div className="f_img_four">*/}
                {/*  <img className="" src={require('../../image/c++.png')}*/}
                {/*       alt="C++" title="C++"/>*/}
                {/*</div>*/}
                {/*<div className="f_img_five">*/}
                {/*  <img className="" src={require('../../image/icons/varnish.webp')}*/}
                {/*       alt=""/>*/}
                {/*</div>*/}
                {/*<div className="f_img_six">*/}
                {/*  <img className="" src={require('../../image/c++.png')}*/}
                {/*       alt="C++" title="C++"/>*/}
                {/*</div>*/}
                {/*<div className="f_img_seven">*/}
                {/*  <img className="" src={require('../../image/search.png')}*/}
                {/*       alt="Search" title="Search"/>*/}
                {/*</div>*/}
                {/*<div className="f_img_eight">*/}
                {/*  <img className="" src={require('../../image/arrow.png')}*/}
                {/*       alt="arrow" title="arrow"/>*/}
                {/*</div>*/}
                {/*<div className="f_img_nine">*/}
                {/*  <img className="" src={require('../../image/brakets.png')}*/}
                {/*       alt="brakets" title="brakets"/>*/}
                {/*</div>*/}
                {/*<div className="f_img_ten">*/}
                {/*  <img className="" src={require('../../image/c++.png')}*/}
                {/*       alt="C++" title="C++"/>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default frelencerBanner;
