import React, {Component} from 'react';
import SectionTitle from '../component/Banner/SectionTitle';
import Fade from 'react-reveal/Fade';

class Service extends Component{
    render(){
        let siteData = this.props.siteData;
        // var {wClass} = this.props
        return(
            // <section className={`${wClass}`} id="services">
            <section className="services" id="services">
                <div className="container">
                    <SectionTitle Title={siteData.service.title} TitleP={siteData.service.description}/>
                    <Fade bottom cascade duration={1000}>
                        <div className="row">
                            {
                                siteData.service.ourServices && siteData.service.ourServices.map(item =>{
                                return(
                                        <div className="col-lg-4 col-sm-6" key={item.id}>
                                            <div className="work_item wow fadeInUp" data-wow-delay="0.1s">
                                                <i className={item.iconName}></i>
                                                <a href=".#" title={item.serviceTitle}><h2 className="t_color">{item.serviceTitle}</h2></a>
                                                <p>{item.sDetails}</p>
                                            </div>
                                        </div>
                                        )
                                    }
                                )
                            }
                        </div>
                    </Fade>
                </div>
            </section>
        )
    }
}

export default Service;
