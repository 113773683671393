const siteData = {
  "name": "Green Art Business",
  "name2": "Stefan Iurasog",
  "name3": "Alexa Johnson",
  "role": "Building Magento2,",
  "ptext": "Creative Designer & Developer located in Romania.",
  "seo": {
    "title": "Green Art Business",
    "titleTemplate": "Building Magento2 and Adobe Commerce",
    "titleSeparator": "|",
    "description": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea minima quidem unde veniam voluptatum. Aperiam aspernatur aut culpa, debitis, esse incidunt iusto mollitia nobis possimus quos reiciendis velit voluptate voluptatibus.",
    "pathname": "https://green-art.net",
    "article": "",
    "image": "",
    "siteLanguage": "en",
    "siteLocale": "en_US",
    "twitterUsername": "",
    "author": "Stefan Iurasog",
    "datePublished": "",
    "dateModified": ""
  },
  "socialLinks": [
    {
      "name": "Facebook",
      "url": "http://www.linkedin.com/",
      "className": "social_facebook"
    },
    {
      "name": "twitter",
      "url": "http://www.twitter.com/",
      "className": "social_twitter"
    },
    // {
    //   "name":"googleplus",
    //   "url":"http://www.googleplus.com",
    //   "className":"social_googleplus"
    // },
    // {
    //     "name":"pinterest",
    //     "url":"http://www.pinterest.com",
    //     "className":"social_pinterest"
    //   }
  ],
  "banner": {
    "text": "With Magento2 & <br/>Adobe Commerce we make outstanding brands stand out.",
    // "framework": "Magento2 & Adobe Commerce",
    "frameworkLogos": [
      {
        "id": 1,
        "title": "Magento2",
        "link": "https://magento.com",
        "logo": "magento-logo.svg"
      },
      {
        "id": 2,
        "title": "Adobe Commerce",
        "link": "https://magento.com",
        "logo": "adobe-commerce-logo.png"
      }
    ]
  },
  "aboutUs": {
    "title": "About Us",
    "sTitle": "Building <strong>Magento2</strong>, <strong>Adobe Commerce</strong>",
    "description": `Established for over <strong>${new Date().getFullYear() - 2006} years now</strong>, we have a fully integrated strong team of specialists covering web design and web development, UX & UI Design, project maintenance, <strong>Magento2 and Adobe Commerce strategy</strong>, creative design and constant support.`,
    "image": "apporoach_man_img.png"
  },
  "whatWeDo": {
    "title": "WHAT WE DO ?",
    "bTitle": "We build delightful experiences, suitable to your needs.",
    "description": "",
    "list": [
      {
        "title": "Service Consulting",
        "image": "service.svg",
        "link": "service-consulting",
        "description": "The experience we gained over time helps us to provide end to end strategic guidance, solutions and counseling."
      },
      {
        "title": "Technical account management",
        "image": "service.svg",
        "link": "technical-account-management",
        "description": "You don’t need technical knowledge to work with us. Just share your goals or challenges & we’ll guide you with a roadmap and budget."
      },
      {
        "title": "Business analysis",
        "image": "service.svg",
        "link": "business-analysis",
        "description": "We help you to keep your daily operation running smoothly all day everyday."
      },
      {
        "title": "Performance checking",
        "image": "service.svg",
        "link": "performance-checking",
        "description": "Our team evaluates the code base and keeps innovating to improve the product quality and performance."
      },
      {
        "title": "Launch your Magento2 & Adobe Commerce site",
        "image": "service.svg",
        "link": "launch-your-magento2-adobe-commerce-site",
        "description": "To get the most of your investment we help you to launch successfully your site."
      },
      {
        "title": "Module Development",
        "image": "service.svg",
        "link": "module-development",
        "description": "The modules are made according to the standard and flow imposed by Magento2."
      }
    ]
  },
  "service": {
    "title": "Our Services",
    "description": "Modesty aside. We are good at devising brand strategies, creating strong identities and designing smart UX & UI for various businesses. ",
    "ourServices": [
      {
        "id": 1,
        "iconName": "icon-desktop",
        "serviceTitle": "UX & UI design",
        "sDetails": "We provide great design to make shopping more enjoyable and faster."
      },
      {
        "id": 2,
        "iconName": "icon-pencil",
        "serviceTitle": "Development",
        "sDetails": "Development projects and extensions with and for Magento2 / Adobe Commerce"
      },
      {
        "id": 3,
        "iconName": "icon-presentation",
        "serviceTitle": "Project maintenance",
        "sDetails": "We are continuously working on improving your project."
      },
      {
        "id": 4,
        "iconName": "icon-linegraph",
        "serviceTitle": "Strategy",
        "sDetails": "We provide profound knowledge on diverse commerce challenges."
      },
      {
        "id": 5,
        "iconName": "icon-gears",
        "serviceTitle": "Architecture",
        "sDetails": "We assist you with architecture planning and decision making."
      },
      {
        "id": 6,
        "iconName": "icon-tools",
        "serviceTitle": "Continuous Delivery",
        "sDetails": "We are flexible in changing all your requirements."
      }
    ]
  },
  "skillsTwo": {
    "atitle": "",
    "btitle": "Some Interesting Facts About Us",
    "description": "Our in-house team of researchers, strategists and creative workers combine their strategic knowledge with creative skills to bring meaningful, lasting change to your brand."
  },
  "contact": [
    {
      "id": "1",
      "title": "Contact Info",
      "menuItems": [
        {
          "id": 1,
          "icon": 'icon_pin_alt',
          "text": 'Address: Z105 - Tan Thinh, Thai Nguyen - Viet Nam',
        },
        {
          "id": 2,
          "icon": 'icon_phone',
          "text": 'Phone: +40 754 596 968',
        },
        {
          "id": 3,
          "icon": 'icon_mail',
          "text": 'Email: office[@]green-art.ro',
        },
        {
          "id": 4,
          "icon": 'icon_globe',
          "text": 'Website: https://green-art.ro',
        }
      ]
    },
  ],
  "applications": {
    "s_title": "My great Applications",
    "b_title": "Some of the applications We Use For The Project To Be A Success.",
    "description": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores cumque doloremque doloribus error eum eveniet expedita itaque necessitatibus quae quia? Alias animi dolorum excepturi fuga ipsum neque officiis porro voluptates."
  },
  "partners": {
    "s_title": "My amazing partners",
    "b_title": "Great Companies we have worked with",
    "description": "Over many years we have had the pleasure of working alongside a range of businesses, helping them to achieve their goals and move their organisations forward.\n"
  },
  "logos": {
    "title": "Trusted by Great People",
    "description": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam blanditiis cum debitis earum eligendi facere ipsam, libero molestias odit pariatur perferendis porro quo similique sint sit sunt ullam vel voluptatibus.",
    "logos": [
      {
        "id": 1,
        "title": "Coffee Chocolate",
        "link": ".#",
        "logo": "1.png"
      },
      {
        "id": 2,
        "title": "Fisherman",
        "link": ".#",
        "logo": "2.png"
      },
      {
        "id": 3,
        "title": "Montainbike",
        "link": ".#",
        "logo": "3.png"
      },
      {
        "id": 4,
        "title": "Montain",
        "link": ".#",
        "logo": "4.png"
      },
      {
        "id": 5,
        "title": "Chocolate",
        "link": ".#",
        "logo": "5.png"
      },
      {
        "id": 6,
        "title": "Market",
        "link": ".#",
        "logo": "6.png"
      }
    ]
  },
  "copywrite": `© ${new Date().getFullYear()} Green Art - All Rights Reserved`
}

export default siteData;
