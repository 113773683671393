import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/elagent-icon/style.css';
import './assets/etline-icon/style.css';
import './assets/flaticon/flaticon.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './assets/css/animate.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'popper.js';
import siteData from './component/siteData';
import SEO from "react-seo-component";

ReactDOM.render(
  <React.Fragment>
    <SEO
      title={siteData.seo.title}
      titleTemplate={siteData.seo.titleTemplate}
      titleSeparator={siteData.seo.titleSeparator}
      description={siteData.seo.description}
      image={siteData.seo.image}
      pathname={siteData.seo.pathname}
      siteLanguage={siteData.seo.siteLanguage}
      siteLocale={siteData.seo.siteLocale}
      twitterUsername={siteData.seo.twitterUsername}
      author={siteData.seo.author}
    />
    <App/>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();
