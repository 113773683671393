import React, {Component} from 'react';
import anime from 'animejs/lib/anime';
import SectionTitleTwo from '../../component/Banner/SectionTitleTwo';
import CounterItem from '../Skill/CounterItem';

class SkillTwo extends Component {
  componentDidMount() {
    function countup(el, target) {
      let data = {count: 0};
      anime({
        targets: data,
        count: [0, target],
        duration: 2000,
        round: 1,
        delay: 200,
        easing: 'easeOutCubic',
        update() {
          el.innerText = data.count.toLocaleString();
        }
      });

    }

    function makeCountup(el) {
      const text = el.textContent;
      const target = parseInt(text, 10);

      const io = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            countup(el, target);
            io.unobserve(entry.target);
          }
        });
      });

      io.observe(el);
    }

    const els = document.querySelectorAll('[data-countup]');

    els.forEach(makeCountup);
  }

  render() {
    let siteData = this.props.siteData.skillsTwo;
    return (
      <section className="skill_area_two bg_color bg_white" id="skill">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="skill_content_two">
                <SectionTitleTwo stitle={siteData.atitle}
                                 btitle={siteData.btitle} />
                <p dangerouslySetInnerHTML={{__html: siteData.description}} />
                <div className="row">
                  <CounterItem col="col-md-6 s_item" CText='200'
                               pdescription='Digital Products'/>
                  <CounterItem col="col-md-6 s_item" CText='22'
                               pdescription='Distributed, fully remote team'/>
                  <CounterItem col="col-md-6 s_item" CText='32'
                               pdescription='Client portal for efficient workflow'/>
                  <CounterItem col="col-md-6 s_item" CText='10'
                               pdescription='Highly systemised processes'/>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img className="fact_img" src={require('../../image/mobile.svg')}
                   alt="Skills" title="Skills"/>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default SkillTwo;
