import React from 'react';
import NavbarTwo from './component/Navbar/NavbarTwo';
import FBanner from './component/Banner/frelencerBanner';
import About from './component/About';
// @todo - st3phan - Portofoliu
// import PortfolioSlider from './component/Portfolio/PortfolioSlider';
import ServiceTwo from './component/ServiceTwo';
import Service from './component/Service';
// import TestimonialTwo from './component/Testimonial/TestimonialTwo';
// import ClientslogoTwo from './component/ClientslogoTwo';
// import Clientslogo from './component/Clientslogo';
// import ApplicationsTwo from './component/ApplicationsTwo';
// @todo - st3phan - Blog
// import BlogTwo from './component/Blog/BlogTwo';
// import Blog from './component/Blog/Blog';
import ContactTwo from './component/ContactTwo';
// import Skill from './component/Skill/Skill';
import SkillTwo from './component/Skill/SkillTwo';
import Footer from './component/Footer';
import siteData from './component/siteData';
// import blogData from './component/blogData';

export const Home = () => (
  <React.Fragment>
    <div className="body_wrapper">
      <NavbarTwo mClass="menu_two" mainlogo="logo_256.svg"
                 stickylogo="logo_icon.svg" siteData={siteData}/>
      <FBanner siteData={siteData}/>
      <ServiceTwo rowFlex="flex-row-reverse" siteData={siteData}/>
      <Service siteData={siteData} rowFlex="flex-row-reverse" className="services"/>
      {/*// @todo - st3phan - Portofoliu*/}
      {/*<PortfolioSlider/>*/}
      {/*<TestimonialTwo siteData={siteData}/>*/}
      <SkillTwo siteData={siteData}/>
      {/* @todo - st3phan - Blog*/}
      {/*<BlogTwo blogData={blogData}/>*/}
      {/*<Blog blogData={blogData}/>*/}
      {/*<ClientslogoTwo siteData={siteData}/>*/}
      {/*<ApplicationsTwo siteData={siteData}/>*/}
      {/*<Clientslogo siteData={siteData}/>*/}
      {/*<Skill siteData={siteData}/>*/}
      <About siteData={siteData}/>
      <ContactTwo siteData={siteData}/>
      <Footer siteData={siteData}/>
    </div>
  </React.Fragment>
)
