import React, {Component} from 'react';
import SectionTitleTwo from '../component/Banner/SectionTitleTwo';

class ServiceTwo extends Component {
  render() {
    let {rowFlex} = this.props;
    let siteData = this.props.siteData.whatWeDo;
    return (
      <div className="service_area_two bg_color" id="whatwedo">
        <div className="container">
          <div className={`row align-items-center ${rowFlex}`}>
            <div className="col-lg-5">
              <div className="service_content">
                <SectionTitleTwo stitle={siteData.title}
                                 btitle={siteData.bTitle}/>
                {/*<p>{siteData.description}</p>*/}
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  {
                    siteData.list.map((item, i) => {
                        return (
                          <li className="nav-item" key={i}>
                            <a className={`nav-link ${i === 0 && "active"}`}
                               id={item.link + "-tab"}
                               data-toggle="tab" href={`#${item.link}`} role="tab"
                               aria-controls={item.link}
                               aria-selected={i === 0 && "true"}
                               title={item.title}>
                              {item.title}
                            </a>
                          </li>
                        )
                      }
                    )
                  }
                </ul>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="tab-content" id="myTabContent">
                {
                  siteData.list.map((item, i) => {
                      return (
                        <div className={`tab-pane fade ${i === 0 && "show active"}`}
                             id={item.link}
                             key={i}
                             role="tabpanel" aria-labelledby={item.link + "-tab"}>
                          <div className="service_img center">
                            <img src={require('../image/' + item.image)} alt={item.title} title={item.title} />
                            <br/>
                            <br/>
                            <span>{item.description}</span>
                          </div>
                        </div>
                      )
                    }
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ServiceTwo;
