import React, {Component} from 'react';
import Sticky from 'react-stickynode';
import {Link} from 'react-scroll';

class NavbarTwo extends Component{
    render(){
        var {mClass, mContainer,mainlogo, stickylogo} = this.props;
        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <nav className={`navbar navbar-expand-lg navbar-light ${mClass}`}>
                    <div className={`container ${mContainer}`}>
                        <h1>
                            <a className="navbar-brand logo_h" href="/" title="Green Art Business">
                                <img src={require ("../../image/" + mainlogo)} alt="Green Art Business" title="Green Art Business"/>
                                <img src={require ("../../image/" + stickylogo)} alt="Green Art Business" title="Green Art Business" width={60} height={60} style={{marginLeft: 88}}/>
                            </a>
                        </h1>
                        {/*<a href="./" className="btn get-btn get-btn-two d-lg-none d-md-block login">Login</a>*/}
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <div className="collapse navbar-collapse offset" id="navbarSupportedContent">
                            <ul className="nav navbar-nav m-auto">
                                <li className="nav-item">
                                    <Link className="nav-link" title="Home" activeClass="active" to="home" spy={true} smooth={true} offset={0} duration={500}>Home</Link>
                                </li>
                                {/*<li className="nav-item">*/}
                                {/*    <Link className="nav-link" activeClass="active" to="/home/test" spy={true} smooth={true} offset={0} duration={500}>Test</Link>*/}
                                {/*</li>*/}
                                <li className="nav-item">
                                    <Link className="nav-link" title="What We Do" activeClass="active" to="whatwedo" spy={true} smooth={true} offset={-86} duration={500}>
                                        What We Do
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" title="Services" activeClass="active" to="services" spy={true} smooth={true} offset={-86} duration={500}>
                                        Services
                                    </Link>
                                </li>
                                 {/*@todo - st3phan - Portofoliu*/}
                                {/*<li className="nav-item">*/}
                                {/*    <Link className="nav-link" activeClass="active" to="portfolio" spy={true} smooth={true} offset={-86} duration={500}>*/}
                                {/*        Portfolio*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                <li className="nav-item">
                                    <Link className="nav-link" title="Skill" activeClass="active" to="skill" spy={true} smooth={true} offset={-86} duration={500}>
                                        Skill
                                    </Link>
                                </li>
                                {/*<li className="nav-item">*/}
                                {/*    <Link className="nav-link" title="Testimonial" activeClass="active" to="testimonial" spy={true} smooth={true} offset={-86} duration={500}>*/}
                                {/*        Testimonial*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/* @todo - st3phan - Blog*/}
                                {/*<li className="nav-item">*/}
                                {/*    <Link className="nav-link" title="Blog" activeClass="active" to="blog" spy={true} smooth={true} offset={-86} duration={500}>*/}
                                {/*        Blog*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                <li className="nav-item">
                                    <Link className="nav-link" title="About Us" activeClass="active" to="about" spy={true} smooth={true} offset={-86} duration={500}>
                                        About Us
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" title="Contact" activeClass="active" to="contacts" spy={true} smooth={true} offset={-86} duration={500}>
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </Sticky>
        );
    }
}

export default NavbarTwo;
