import React, {Component} from 'react';
// import Fade from 'react-reveal/Fade';
class Footer extends Component{
    render(){
        let siteData = this.props.siteData;
        return(
            <section className="footer-area-two">
                <div className="container">
                    <div className="row footer-content">
                        {/*<div className="col-sm-4">*/}
                        {/*    <Fade top cascade>*/}
                        {/*        <ul className="list_style">*/}
                        {/*            {*/}
                        {/*                siteData.socialLinks.map(item =>{*/}
                        {/*                    return(*/}
                        {/*                        <li key={item.name} data-name={item.name}>*/}
                        {/*                            <a href={item.url} title={item.name}><i className={item.className}></i></a>*/}
                        {/*                        </li>*/}
                        {/*                    )*/}
                        {/*                })*/}
                        {/*            }*/}
                        {/*        </ul>*/}
                        {/*    </Fade>*/}
                        {/*</div>*/}
                        <div className="col-sm-8 text-right">
                            <p>{siteData.copywrite}</p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Footer;
